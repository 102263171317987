<template>
    <div class="out" :class="$i18n.locale == 'en'?'en_style':''">
      <div class="header">
              <div class="logo Title">
                  <div class="English">CERTIFICATION TRANSFER</div>
                  <div class="Chinese">{{$t('main.holdertran')}}</div>
              </div>
              <div class="right-icon">
                  <img src="../assets/img/logo-icon.png" alt="">
                  <div class="cc-wc">
                      {{$t('main.titleCenter')}}
                  </div>
              </div>
          </div>
          <div class="body">
              
              <div class="list_item">
                  {{$t('main.afhct')}}
              </div>
              <div class="list_item flex" style="margin-bottom:0rem" >
                  
                  <div class="">
                      <div class="list_item">
                          <span>{{$t('main.trname')}}：</span>
                          <span>{{goods.name}}</span>
                      </div>
                       <div class="list_item">
                          <span>{{$t('main.gwtc')}}</span>
                          <span style="color:#fe0000">{{goods.serial_number}}</span>
                      </div>
                       <div class="list_item">
                          <span>{{$t('main.mv')}}：</span>
                          <span>￥{{format_with_regex(goods.price)}}</span>
                      </div>
                      <div class="list_item">
                        <span>{{$t('main.lastva')}}</span>
                        <span style="color:#2d71d7">￥{{format_with_regex(new_price)}}</span>
                      </div>
                  </div>
              </div>
              <div class="price_flex">
                <div>￥{{format_with_regex(price_list[0])}}</div>
                <div>￥{{format_with_regex(price_list[1])}}</div>
              </div>
              <van-slider :min="price_list[0]" :max="price_list[1]" v-model="new_price" bar-height="0.14rem" active-color="#2d71d7"></van-slider>
              <div class="qujian" v-if="price_list.length">
                {{$t('zr.tips')}}{{format_with_regex(price_list[0])}}~￥{{format_with_regex(price_list[1])}}
              </div>
              <div class="list_item">
                  <div>{{$t('main.holderinshow')}}</div>
                  <div >
                      <div class="flex check_box" @click="changeCustom(false)">
                           <!-- @change="recommend($event,item.id)" -->
                          <input type="radio"   name="test" :checked="!is_custom" >
                          <label class="user_name">
                              <div>{{user.username}}</div>
                          </label>
                      </div>
                      <div class="flex check_box" @click="changeCustom(true)">
                          <input type="radio"  name="test" :checked="is_custom" >
                          <label class="list_input">
                              <input type="text" :placeholder="$t('main.userdefin')" :disabled="!is_custom" v-model="custom_name">
                          </label>
                      </div>
                      
                  </div>
              </div>
              <div class="list_item" style="color:#4ab006">
                {{$t('main.pfbs')}}{{transfer_fee}}
              </div>
              <div class="agree-box1" v-show="is_xy_show">
                      <input @input="checkedFlag1" type="checkbox" value="" id="agree-select1" :checked="isKnow" />
                      <label id="" for="agree-select1"> {{$t('main.agreeC')}} </label>
                      <span class="blue_xy" @click.stop="showContent">《{{xy.title}}》</span>
                  </div>
               <div class="list_item">
                {{$t('main.notepay')}}
               </div>
              
               
          </div>
          <div class="bottom_all">
            <div @click="upload" class="comfirm_button">{{$t('main.confirmandpay')}}</div>
          </div>
          <div class="pop pop_mask" v-show="shouquan" @click="shouquan = false" ></div>
          <privacy ref="privacy" :more="$route.query.qrCode?`&qrCode=${$route.query.qrCode}`:''"></privacy>
    </div>
</template>
  
  <script>
  import privacy from "../components/privacy.vue"
  import { Dialog } from 'vant';
  export default {
    components:{
	privacy
  },
      data(){
          return{
              goods:{
                  name:'',
                  serial_number:'',
                  price:'',
                  id:''
              },
              user:{
                  id:0,
                  username:'',
                  avatar:''
              },
              is_custom:false,
              custom_name:'',
              new_price:0,
              transfer_fee:'',
              shouquan:false,
              price_list:[],
              isKnow:true,
				xy:{
					title:'',
					content:''
				},
                is_xy_show:false,
            isToken:false
          }
      },
      async mounted(){
            // let url = location.protocol+'//' + location.host
			// let param = this.$route.fullPath
			// this.axios.post('/auth/wechatConfig', {
			// 	url: url + param,
			// 	jsApiList: ['chooseWXPay']
			// }).then((res) => {
			// 	
			// 	let json = JSON.parse(res.data.data.data)

			// 	wx.config({
			// 		debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
			// 		appId: json.appId, // 必填，公众号的唯一标识
			// 		timestamp: json.timestamp, // 必填，生成签名的时间戳
			// 		nonceStr: json.nonceStr, // 必填，生成签名的随机串
			// 		signature: json.signature, // 必填，签名
			// 		jsApiList: json.jsApiList // 必填，需要使用的 JS 接口列表
			// 	})

			// })
            let querys= ''
            if(this.$route.query.phone){
                let phone = this.$route.query.phone
                let nickName = this.$route.query.phone_code
                querys = `&phone=${phone}&phone_code=${decodeURIComponent(nickName)}`
            }
              this.axios.get(`/collection/apply?code=${this.$route.query.code}&qrCode=${this.$route.query.qrCode}`+querys).then((res)=>{
                  if(res.data.code != 0){
                    if(res.data.msg == '请填写手机号'){
                         this.$refs.privacy.openPopup()
                        return
                    }else if(res.data.msg == '请授权登录'){
                        this.shouquan = true
                    }else{
                        weui.alert('该二维码已被使用，请返回重新获取最新二维码',{buttons:[{label:this.$t('main.confIrm')}]})
                    }
                      
                      return
                  }
                  this.isToken = true
                  if(this.$i18n.locale == 'en'){
                    if(res.data.data.goods.name_intl){
                        res.data.data.goods.name = res.data.data.goods.name_intl
                    }  
                  }
                  this.goods.name = res.data.data.goods.name
                  this.goods.id = res.data.data.goods.id
                  this.goods.serial_number = res.data.data.goods.serial_number
                  this.goods.price = res.data.data.goods.collector? res.data.data.goods.collector.price : res.data.data.goods.price
                  this.new_price = parseInt(JSON.parse(JSON.stringify(this.goods.price)))
                  this.transfer_fee = res.data.data.goods.level.transfer_fee
                  this.user.id = res.data.data.user.id
                  this.user.username = res.data.data.user.username
                  this.user.avatar = res.data.data.user.avatar
                  this.price_list = res.data.data.price_range
                  
              })
              let newdata = ''
			await this.axios.post('common/encrypt',{type:3 + (this.$i18n.locale == 'en' ? 6 : 0)}).then((res)=>{
				newdata = res.data.data.data_encrypted
			})
              this.axios.post('/agreement/detail',{data:newdata}).then((res)=>{
				
				if(!res.data.data.title || !res.data.data.content){
						this.isKnow = true
						this.is_xy_show=false
						
						return
				}
				this.is_xy_show = true
				this.xy.title = res.data.data.title
				this.xy.content = res.data.data.content
			})
          
          
      },
      methods:{
          upload(){
            if(!this.isToken){
                this.$refs.privacy.showFlag()
                return
            }
             if(!this.isKnow){
                weui.alert(this.$t('main.paoaf'),{buttons:[{label:this.$t('main.confIrm')}]})
                return
              }
              if(!this.new_price){
                weui.alert(this.$t('main.pftlv'),{buttons:[{label:this.$t('main.confIrm')}]})
                
                return
              }
              
            //   let onPrice = Math.abs(parseFloat(this.goods.price) - parseFloat(this.new_price)) / parseFloat(this.goods.price)
            //   
            //   if(onPrice > 0.1){
            //     weui.alert('珍宝估值调整必须在指定区间内')
            //     return
            //   }
              let params = {
                  user_id:this.user.id,
                  goods_id:this.goods.id,
                  name:this.user.username,
                  avatar:this.user.avatar ? this.user.avatar: 'http://t.6a.com/87/011930525c7d7011.png',
                  is_custom:this.is_custom?1:0,
                  qrCode:this.$route.query.qrCode,
                  price:this.new_price,
                  parent_id:this.$route.query.parent_id
              }
              if(this.is_custom){
                  if(!this.custom_name){
                    weui.alert(this.$t('main.holderinoe'),{buttons:[{label:this.$t('main.confIrm')}]})
                    return 
                  }
                  params.name = this.custom_name
              }
              this.axios.post('/collection/apply',params).then((res)=>{
                  
                  if(res.data.code == 0){
                    let wxpay = res.data.data
                    
                    WeixinJSBridge.invoke(
                    'getBrandWCPayRequest', {
                        appId:wxpay.appId,
                        timeStamp: wxpay.timestamp, 
                        nonceStr: wxpay.nonceStr, // 支付签名随机串，不长于 32 位
                        package: wxpay.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                        signType: wxpay.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
                        paySign: wxpay.paySign,
                    },(res)=>{
      if(res.err_msg == "get_brand_wcpay_request:ok" ){
        weui.alert(this.$t('main.paysucc'),{buttons:[{label:this.$t('main.confIrm'),onClick:()=>{
           this.$router.push('/zbbox')
        }}]})
      }else{
        weui.alert(this.$t('main.payfail'),{buttons:[{label:this.$t('main.confIrm')}]})
      } })
                    // wx.chooseWXPay({
                    //     timestamp: wxpay.timestamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
                    //     nonceStr: wxpay.nonceStr, // 支付签名随机串，不长于 32 位
                    //     package: wxpay.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                    //     signType: wxpay.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
                    //     paySign: wxpay.paySign,
                    //     appid: wxpay.appid,
                    //     success:()=>{
                    //         weui.alert(this.$t('main.submitsucc'),()=>{
                    //           this.$router.push('/zbbox')
                    //         })
                    //     },
                    //     fail:()=>{
                    //         weui.alert(this.$t('main.paysucc'))
                    //     }
                    // })
                    
                    //   
                      
                  }else{
                      weui.alert(res.data.msg,{buttons:[{label:this.$t('main.confIrm')}]})
                      
                  }
              })
          },
          format_with_regex(number) {
            if(!number){
                return 0
            }
          return !(number + '').includes('.')
              ? // 就是说1-3位后面一定要匹配3位
              (number + '').replace(/\d{1,3}(?=(\d{3})+$)/g, (match) => {
                  return match + ',';
              })
              : (number + '').replace(/\d{1,3}(?=(\d{3})+(\.))/g, (match) => {
                  return match + ',';
              });
          },
          changeCustom(bool){
              this.is_custom = bool
          },
          checkedFlag1(val) {
				this.isKnow = !this.isKnow
				
			},
            showContent(){
				Dialog.alert({message:this.xy.content,confirmButtonColor:'#2d71d7',confirmButtonText:this.$t('main.confIrm')})
			},
      }
  }
  </script>
  
  <style scoped>
  .out{
      box-sizing: border-box;
      background: url(../assets/img/index_bg.png) no-repeat center;
          background-size: cover;
      min-height: 100vh;
  }
  .header {
          display: flex;
          justify-content: space-between;
          
          background: #fff;
          padding: 0.06rem 0.15rem;
          align-items: center;
          box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
          
  }
  .Title {
          font-size: 0.16rem;
          position: relative;
      }
      .alcent{
        align-items: center;
      }
      .English {
              white-space: nowrap;
              color: #d6eedc;
              position: absolute;
              top: 0.1rem;
              left: 0.15rem;
      }
  
      .Chinese {
          color: #3662EC;
          /* top: -0.2rem; */
          left: 0;
          position: relative;
          text-align: left;
          font-size: 0.18rem;
      }
      .right-icon {
          /* width: 1rem; */
          display: flex;
              flex-direction: column;
              align-items: flex-end;
      }
      
      .right-icon img {
          display: block;
          width: 100%;
          height: 100%;
              width: 0.65rem;
      }
      .cc-wc {
          font-size: 0.1rem;
          color: #e9630a;
      }
      .body{
          margin:   0.15rem;
          padding: 0.15rem;
          background: #fff;
      }
      .list_item{
          color: #343434;
          font-size: 0.15rem;
          margin-bottom: 0.3rem;
      }
      .list_item .list_item{
        margin-bottom: 0.12rem;
      }
      .flex{
          display: flex;
          line-height: 0.2rem;
      }
      .list_input{
       
          width: 1.9rem;
          height: 0.33rem;
          
          border-radius: 6px;
  background: #FFFFFF;
  box-shadow: 0 0 0 0.01rem #a0a0a0 ;
  
  
  font-size: 0.14rem;
      }
      
      .list_input input{
          border: 0;
          width: 1.9rem;
          height: 0.33rem;
          box-sizing: border-box;
          padding: 0 0.1rem;
          color: #9E9E9E;
          font-size: 0.14rem;
           border-radius: 6px;
           line-height: 0.33rem;
      }
      .list_input1{
        margin-left: auto;
        width: 2.2rem;
          height: 0.33rem;
          
          border-radius: 6px;
  background: #FFFFFF;
  box-shadow: 0 0 0 0.01rem #a0a0a0 ;
  
  
  font-size: 0.14rem;
      }
      .list_input1 input{
          border: 0;
          width: 2.2rem;
          height: 0.33rem;
          box-sizing: border-box;
          padding: 0 0.1rem;
          color: #9E9E9E;
          font-size: 0.14rem;
           border-radius: 6px;
           line-height: 0.33rem;
      }

      .list_input div{
          border: 0;
          width: 1.9rem;
          height: 0.35rem;
          box-sizing: border-box;
          padding: 0 0.1rem;
          color: #9E9E9E;
          font-size: 0.14rem;
          line-height: 0.35rem;
      }
      .user_name{
        padding: 0 0.1rem;
        color: #9E9E9E;
         font-size: 0.14rem;
          line-height: 0.35rem;
      
      }
      .check_box{
          position: relative;
          align-items: center;
          margin-bottom: 0.08rem;
          margin-left: 0.7rem;
      }
      .check_box > input{
          visibility: hidden;
          
          width: 0.19rem;
          height: 0.19rem;
          border-radius: 50%;
          border: none;
          /* box-shadow: 0 0 0 1px #c1c1c1; */
          margin-right: 0.07rem;
      }
      .check_box label::before {
          display: inline-block;
          content: "";
          width: 0.2rem;
          height: 0.2rem;
           left: 0rem;
          top: 0.095rem;
          position: absolute;
          background: url(../assets/img/checked-not-icon.png) no-repeat center;
          background-size: 100%;
      }
  
      .check_box input:checked+label::before {
          display: inline-block;
          content: "";
          width: 0.2rem;
          height: 0.2rem;
          
          background: url(../assets/img/rz-select-iocn.png) no-repeat center;
          background-size: 100%;
          box-shadow: none;
   left: 0rem;
          top: 0.075rem;
          position: absolute;
      }
      .check_box input:checked+.list_input{
          box-shadow: 0 0 0 0.01rem #3662EC;
      }
      .check_box input:checked+.user_name{
        color: #3662EC;
      }
      .agree-box1 input{
          display: none;
      }
      .agree-box1 {
          display: flex;
          align-items: center;
              position: relative;
              margin-top: 0.2rem;
      }
      .agree-box1 label::before{
         display: inline-block;
             content: "";
             width: 0.175rem;
             height: 0.175rem;
             background-color: #ffffff;
             border-radius: 50%;
             left: 0;
             top: 50%;
             transform: translateY(-50%);
             position: absolute;
             border: 1px solid #bfbfbf;
             box-sizing: border-box;
      }
      .agree-box1 input:checked+label::before{
         display: inline-block;
             content: "";
             width: 0.175rem;
             height: 0.175rem;
             border-radius: 50%;
             position: absolute;
             left: 0;
             top: 50%;
             transform: translateY(-50%);
             background: url(../assets/img/rz-select-iocn.png) no-repeat center;
             background-size: 100% 100%;
      }
      .agree-box1 label{
          font-size: 0.12rem;
          margin-left: 0.325rem;
          line-height: 0.175rem;
      } 
      .upload{
          
          bottom: 0.36rem;
          background: #3662EC;
          box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4);
          margin: 0 auto;
          width: 3.2rem;
          height: 0.45rem;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #FFFFFF;
          font-size: 0.2rem;
          border-radius: 0.1rem;
      }
      .button_list{
        justify-content: space-between;
      }
      .button_list div{
        width: 1.525rem;
        height: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.18rem;
        color: #fff;
        border-radius: 0.05rem;
      }
      .button_list div:nth-child(1){
        background: #535353;
      }
      .button_list div:nth-child(2){
        background: #2d71d7;
      }
      .pop{
		position: fixed;
		width: 100%;
		height: 100vh;
		background: rgba(0, 0, 0, 0.5);
		top: 0;
		left: 0;
        z-index: 2;
	}
      .pop_mask{
		background: url(../assets/img/kz_pop.png)no-repeat;
		background-size: 100% 100%;
	}
    .qujian{
        margin-top: 0.1rem;
        font-size: 0.12rem;
        color: #b82b25;
        margin-bottom: 0.1rem;
    }
    .blue_xy{
		color: #2d71d7;
		font-size: 0.12rem;
		line-height: 0.175rem;
	}
    .price_flex{
        display: flex;
        justify-content: space-between;
        font-size: 0.11rem;
        color: #343434;
        margin-bottom: 0.05rem;
    }
    :deep(.van-slider__button){
        width: 0.2rem;
        height: 0.27rem;
        border-radius: 0.05rem;
        background: url(../assets/img/slider.png) no-repeat;
        background-color: #fff;
        background-size: 0.07rem 0.12rem;
        background-position: center center;
    }
    .bottom_all{
        position: fixed;
        width: 100%;
        height: 0.62rem;
        bottom: 0;
        box-shadow: 0 -1px 6px 0 #626262;
        background: #edeae4;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .comfirm_button{
        width: 2.87rem;
        height: 0.5rem;
        background: url(../assets/img/my-zb-btn.png) no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: 2.79rem 0.435rem;
        background-position: center center;
        background-color: #2d71d7;
        border-radius: 0.05rem;
        font-size: 0.18rem;
        color: #fff;
    }
    .en_style .list_item.flex{
        flex-wrap: wrap;
       
    }
    .en_style .list_item.flex > div:nth-child(1){
        margin-bottom: 0.12rem;
    }
  </style>